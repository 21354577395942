import { useEffect, useState } from 'react'
import { Product } from '@Types/product/Product'
import { NEW_ARRIVAL_STAMP } from 'composable/components/general/constants'
import { StarterKitAlgoliaProduct } from 'composable/components/plp/types'

// This component is used to display labels (PRODUCT_BADGES) in category page, PDP and quick view
// see ProductBadges for how it works

export const useCheckIfProductHasBadge = (
  product: Product | Partial<StarterKitAlgoliaProduct>,
  isAlgoliaProduct = false,
  currentPDPVariant,
  windowLocation,
) => {
  const [badgeValues, setBadgeValues] = useState({
    hasEdsCode: false,
    isExclusiveSizes: false,
    isOnlineOnly: false,
    isSustainable: false,
    isNew: false,
  })

  const isStarterKitAlgoliaProduct = (product: any): product is Partial<StarterKitAlgoliaProduct> => {
    return true // No checks, just assume it's StarterKitAlgoliaProduct if we are using this function
  }

  useEffect(() => {
    let hasEdsCode = false,
      isExclusiveSizes = false,
      isOnlineOnly = false,
      isSustainable = false

    let isNew = windowLocation.includes('/c/new')

    if (isAlgoliaProduct) {
      hasEdsCode = isStarterKitAlgoliaProduct(product) && !!product?.hasEdsCode
      isExclusiveSizes = isStarterKitAlgoliaProduct(product) && !!product?.isExclusiveSizes
      isOnlineOnly = isStarterKitAlgoliaProduct(product) && !!product?.isOnlineOnly
      isSustainable = isStarterKitAlgoliaProduct(product) && !!product?.isGreenLeafApproved
    } else {
      hasEdsCode = !!product?.attributes?.hasEdsCode
      isExclusiveSizes = !!product?.attributes?.isExclusiveSizes
      isOnlineOnly = !!product?.attributes?.isOnlineOnly
      isSustainable = !!currentPDPVariant?.attributes?.isGreenLeafApproved

      if (!isNew) {
        const actualGoLiveDate = currentPDPVariant?.attributes?.ctActualGoLiveDateAsDateType ?? ''
        const actualGoLiveTimestamp = Math.floor(new Date(actualGoLiveDate).getTime() / 1000)
        isNew = actualGoLiveTimestamp >= NEW_ARRIVAL_STAMP
      }
    }

    // Update state with recalculated values
    setBadgeValues({
      hasEdsCode,
      isExclusiveSizes,
      isOnlineOnly,
      isSustainable,
      isNew,
    })
  }, [currentPDPVariant, product, isAlgoliaProduct, windowLocation])

  return {
    hasBadge:
      badgeValues.hasEdsCode ||
      badgeValues.isExclusiveSizes ||
      badgeValues.isOnlineOnly ||
      badgeValues.isSustainable ||
      badgeValues.isNew,
    badgeValues,
  }
}
