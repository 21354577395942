import { AlgoliaFilterItem } from './types'

export const DEFAULT_SORTBY = 'primary_index'
export const DEFAULT_INITIAL_REFINEMENTS_LIMIT = 6
export const DEFAULT_SHOW_MORE_REFINEMENTS_LIMIT = process.env.NEXT_PUBLIC_DEFAULT_MAX_VALUES_PER_FACET
  ? parseInt(process.env.NEXT_PUBLIC_DEFAULT_MAX_VALUES_PER_FACET)
  : 100
export const DEFAULT_MAX_FACET_HITS = process.env.NEXT_PUBLIC_DEFAULT_MAX_FACET_HITS
  ? parseInt(process.env.NEXT_PUBLIC_DEFAULT_MAX_FACET_HITS)
  : 50
export const ALGOLIA_BASE_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_BASE_INDEX ?? ''
export const BRAND_NAME = process.env.NEXT_PUBLIC_BRAND_NAME ?? ''

export const ALGOLIA_FILTERS: AlgoliaFilterItem[] = [
  {
    type: 'list',
    attribute: 'availability.availabilestore',
    urlAlias: 'storePickup',
    translationKey: 'Free Store Pickup',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'department.name',
    urlAlias: 'department',
    translationKey: 'category.refinements.department',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'brand',
    urlAlias: 'brand',
    translationKey: 'category.refinements.brand',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'facetColors',
    urlAlias: 'facetColors',
    translationKey: 'category.refinements.facetColors',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'Sizes',
    urlAlias: 'Sizes',
    translationKey: 'category.refinements.size',
    operator: 'or',
    /*
      We track `Sizes` which is [ 28, 30, ..Sizes ]
      But on UI, we call it "Size"
    */
  },
  {
    type: 'range',
    attribute: 'price.centAmount',
    urlAlias: 'price',
    translationKey: 'category.refinements.price.centAmount',
    operator: 'and',
  },
  {
    type: 'list',
    attribute: 'features',
    urlAlias: 'features',
    translationKey: 'category.refinements.features',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'performanceFeatures',
    urlAlias: 'performanceFeatures',
    translationKey: 'category.refinements.performanceFeatures',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'sleeveLength',
    urlAlias: 'sleeveLength',
    translationKey: 'category.refinements.sleeveLength',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'PantFit',
    urlAlias: 'PantFit',
    translationKey: 'category.refinements.PantFit',
    operator: 'or',
  },

  {
    type: 'list',
    attribute: 'graphic',
    urlAlias: 'graphic',
    translationKey: 'category.refinements.graphic',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'hatTypes',
    urlAlias: 'hatTypes',
    translationKey: 'category.refinements.hatTypes',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'jacketStyle',
    urlAlias: 'jacketStyle',
    translationKey: 'category.refinements.jacketStyle',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'JacketWeight',
    urlAlias: 'JacketWeight',
    translationKey: 'category.refinements.JacketWeight',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'league',
    urlAlias: 'league',
    translationKey: 'category.refinements.league',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'material',
    urlAlias: 'material',
    translationKey: 'category.refinements.material',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'occasion',
    urlAlias: 'occasion',
    translationKey: 'category.refinements.occasion',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'PackageQty',
    urlAlias: 'PackageQty',
    translationKey: 'category.refinements.PackageQty',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'combinedCollarAndShirtTypes',
    urlAlias: 'combinedCollarAndShirtTypes',
    translationKey: 'category.refinements.combinedCollarAndShirtTypes',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'shortsInseam',
    urlAlias: 'shortsInseam',
    translationKey: 'category.refinements.Inseam',
    operator: 'or',
    /*
      We track `shortsInseam`
      But on UI, we call it "Inseam"
    */
  },
  {
    type: 'list',
    attribute: 'sockHeight',
    urlAlias: 'sockHeight',
    translationKey: 'category.refinements.sockHeight',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'style',
    urlAlias: 'style',
    translationKey: 'category.refinements.style',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'accessoryTypes',
    urlAlias: 'accessoryTypes',
    translationKey: 'category.refinements.accessoryTypes',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'pantShortTypes',
    urlAlias: 'pantShortTypes',
    translationKey: 'category.refinements.pantShortTypes',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'printsPatterns',
    urlAlias: 'printsPatterns',
    translationKey: 'category.refinements.printsPatterns',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'Types',
    urlAlias: 'Types',
    translationKey: 'category.refinements.Types',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'Inseams',
    urlAlias: 'Inseams',
    translationKey: 'category.refinements.pantlength',
    operator: 'or',
    /*
      We track `Inseams` which is [ 28, 30, ..inseams ]
      But on UI, we call it "Jeans & Pant Length"
    */
  },

  {
    type: 'list',
    attribute: 'Waists',
    urlAlias: 'Waists',
    translationKey: 'category.refinements.waistSize',
    operator: 'or',
    /*
      We track `Waists` which is [ 28, 30, ..Waists ]
      But on UI, we call it "waistSize"
    */
  },
  {
    type: 'list',
    attribute: 'categories',
    urlAlias: 'categories',
    translationKey: 'category.refinements.ancestorCategotyIds',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'Coat Length',
    urlAlias: 'Coat Length',
    translationKey: 'category.refinements.coatlength',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'Coat Sizes',
    urlAlias: 'Coat Sizes',
    translationKey: 'category.refinements.coatsizes',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'Big or Tall',
    urlAlias: 'Big or Tall',
    translationKey: 'category.refinements.bigOrTall',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'Neck Sizes',
    urlAlias: 'Neck Sizes',
    translationKey: 'category.refinements.necksizes',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'sleevesize',
    urlAlias: 'sleevesize',
    translationKey: 'category.refinements.sleevesize',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'Shoe Size',
    urlAlias: 'Shoe Size',
    translationKey: 'category.refinements.shoesize',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'Shoe Width',
    urlAlias: 'Shoe Width',
    translationKey: 'category.refinements.shoewidth',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'team',
    urlAlias: 'team',
    translationKey: 'category.refinements.teams',
    operator: 'or',
  },
]

export const CATEGORY_SEARCH_FIELDS = [
  // Not needed for now, just use Algolia config dashboard
]

export const ALGOLIA_INDEX_LIST = [
  { label: 'Best Match', value: 'primary_index' },
  { label: 'Name', value: 'nameAsc' },
  { label: 'Best Selling', value: 'bestseller' },
  { label: 'Newest Arrivals', value: `newest` },
  { label: 'Price (High to Low)', value: 'priceDesc' },
  { label: 'Price (Low to High)', value: 'priceAsc' },
  { label: 'Discount (High to Low)', value: 'discountDesc' },
  { label: 'Product Review Rating High-Low', value: 'powerReviewRatingDesc' },
]

export const LOCALES = [
  { languageCode: 'en', countryLanguageCode: 'en-US', currency: 'USD' },
  { languageCode: 'en', countryLanguageCode: 'en-CA', currency: 'CAD' },
  { languageCode: 'fr', countryLanguageCode: 'fr-CA', currency: 'CAD' },
] as const

export const SELLER_FILTER_FIELD = 'attributes.normalized.seller'

export const DEFAULT_RANKING_OPTIONS = ['typo', 'geo', 'words', 'filters', 'proximity', 'attribute', 'exact']

export const PRIMARY_INDEX_SETTINGS = {
  attributesForFaceting: [
    'brand',
    'size',
    'facetColors',
    'price.centAmount',
    'department',
    'features',
    'performanceFeatures',
    'sleeveLength',
    'PantFit',
  ],
  searchableAttributes: [
    'name',
    'brand',
    'size',
    'facetColors',
    'categories',
    'department',
    'features',
    'performanceFeatures',
    'sleeveLength',
    'PantFit',
  ],
  ranking: [...DEFAULT_RANKING_OPTIONS, 'desc(timestampCreated)'],
}

export const REPLICAS = [
  {
    name: 'newest',
    ranking: 'desc(timestampCreated)',
  },
  {
    name: 'priceDesc',
    ranking: 'desc(price.centAmount)',
  },
  {
    name: 'priceAsc',
    ranking: 'asc(price.centAmount)',
  },
  {
    name: 'nameAsc',
    ranking: 'asc(name)',
  },
  {
    name: 'bestseller',
    ranking: 'desc(quantity_sold)',
  },
  {
    name: 'discountDesc',
    value: 'desc(discountAmount)',
  },
  {
    name: 'powerReviewRatingDesc',
    value: 'desc(powerReviewRating)',
  },
]

export const PRODUCT_BADGES = {
  EXCLUSIVE_SIZES: 'Only at DXL',
  ONLINE_ONLY: 'Online Exclusive',
  EDS_CODE: 'Buy More Save More',
  SUSTAINABLE: 'Sustainable',
  NEW: 'New',
}

const ATTRIBUTE_ALGOLIA_FILTER = ALGOLIA_FILTERS.map((filter) => filter.attribute)

export const NOT_ACCEPTED_PARAMS = ['slug', ...ATTRIBUTE_ALGOLIA_FILTER]
export const ALGOLIA_QUERY_ID = 'aqid'
export const MAX_HITS_PER_PAGE = 12 // Default value from Algolia
