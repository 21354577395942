import { useEffect, useState } from 'react'
import { HStack, Tag, Text } from '@chakra-ui/react'
import { PRODUCT_BADGES } from 'composable/components/plp/constants'

export default function ProductBadges({
  badgeValues,
}: {
  badgeValues: {
    hasEdsCode: boolean
    isExclusiveSizes: boolean
    isOnlineOnly: boolean
    isSustainable: boolean
    isNew: boolean
  }
}) {
  const { isNew, isSustainable, hasEdsCode, isExclusiveSizes, isOnlineOnly } = badgeValues

  const [badgesList, setBadgesList] = useState([])
  useEffect(() => {
    let badgesList = []
    if (isNew) {
      badgesList.push(PRODUCT_BADGES.NEW)
    }
    if (isSustainable) {
      badgesList.push(PRODUCT_BADGES.SUSTAINABLE)
    }
    if (hasEdsCode) {
      badgesList.push(PRODUCT_BADGES.EDS_CODE)
    }
    if (isExclusiveSizes) {
      badgesList.push(PRODUCT_BADGES.EXCLUSIVE_SIZES)
    }
    if (isOnlineOnly) {
      badgesList.push(PRODUCT_BADGES.ONLINE_ONLY)
    }

    setBadgesList(badgesList)
  }, [hasEdsCode, isExclusiveSizes, isOnlineOnly, isSustainable, isNew])

  return (
    <HStack gap={2} flexWrap={'wrap'}>
      {badgesList.map((badge, _) => (
        <Tag
          padding={'2'}
          borderRadius="base"
          height={'6'}
          size={'14px'}
          bg={badge === PRODUCT_BADGES.SUSTAINABLE ? '#D9FADB' : 'surface.muted'}
          key={badge}
        >
          <Text
            textStyle="ui-button-200"
            tabIndex={0}
            _focus={{
              outline: '2px solid',
              outlineColor: 'blue.500',
            }}
          >
            {badge}
          </Text>
        </Tag>
      ))}
    </HStack>
  )
}
