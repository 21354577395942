import Script from 'next/script'
import { useEffect, useState } from 'react'

interface PDPVideoProps {
  videoUrl: string
  id: number
}

export const PDPVideo = ({ videoUrl, id }: PDPVideoProps) => {
  const isScene7Video = videoUrl.startsWith('https://images.dxl.com/is/image/') ? true : false
  const assetURL = isScene7Video ? videoUrl.split('https://images.dxl.com/is/image/')?.[1] : videoUrl
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)

  useEffect(() => {
    if (isScriptLoaded) {
      const containerId = `scene7-video-container-${id}`

      const videoViewer = new (window as any).s7viewers.VideoViewer({
        containerId,
        params: {
          asset: assetURL,
          serverurl: 'https://images.dxl.com/is/image/',
          contenturl: 'https://images.dxl.com/skins/',
          config: 'Scene7SharedAssets/Universal_HTML5_Video',
          emailurl: 'https://images.dxl.com/s7/emailFriend',
          videoserverurl: 'https://images.dxl.com/is/content/',
        },
      })
      videoViewer.init()
    }
  }, [videoUrl, isScriptLoaded])

  return (
    <>
      {isScene7Video ? (
        <div id={`scene7-video-container-${id}`} style={{ width: '100%', height: '100%' }}></div>
      ) : (
        <iframe src={videoUrl} width="100%" height="100%" allowFullScreen></iframe>
      )}
      <Script
        src={`https://images.dxl.com/s7viewers/html5/js/VideoViewer.js`}
        async={true}
        id="imageviewsync"
        onLoad={() => setIsScriptLoaded(true)}
        onReady={() => setIsScriptLoaded(true)}
      ></Script>
    </>
  )
}
