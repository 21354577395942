import { useState } from 'react'
import Script from 'next/script'
import { Scene7Viewer } from './scene-7-viewer'
import { ImagesAndVideos } from 'composable/components/gallery'

interface Scene7ViewerScriptProps {
  isOpen: boolean
  onClose: () => void
  productVideos?: ImagesAndVideos[] | []
  currentImages?: any[] | []
  currentImageIndex: number
}

export const Scene7ViewerScript = ({
  isOpen,
  onClose,
  productVideos,
  currentImages,
  currentImageIndex,
}: Scene7ViewerScriptProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const onLoadHandler = () => {
    setIsLoaded(true)
  }

  if (isOpen && isLoaded) {
    return (
      <Scene7Viewer
        isOpen={isOpen}
        onClose={onClose}
        productVideos={productVideos}
        productImages={currentImages}
        initialCurrentImageIndex={currentImageIndex}
      />
    )
  }

  if (!isOpen || isLoaded) return null

  return (
    !isLoaded && (
      <Script
        src="https://images.dxl.com/s7viewers/html5/js/BasicZoomViewer.js"
        strategy="afterInteractive"
        onLoad={onLoadHandler}
      />
    )
  )
}
