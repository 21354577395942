import { useEffect, useState } from 'react'
import Head from 'next/head'
import {
  Box,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Image as ChakraImage,
  ModalFooter,
  useBreakpointValue,
} from '@chakra-ui/react'
import { ImagesAndVideos } from 'composable/components/gallery'
import { CONTENT_URL, getImageAsset } from 'composable/helpers/utils/image-utils'
import { IoChevronForward, IoChevronBack, IoPlayCircleOutline } from 'react-icons/io5'

export interface Scene7ViewerScriptProps {
  isOpen: boolean
  onClose: () => void
  productVideos: ImagesAndVideos[] | []
  productImages: ImagesAndVideos[] | []
  initialCurrentImageIndex: number
}

interface Window {
  s7viewers?: any
}

const renderMedia = (media) => {
  if (media.type === 'video') {
    const isScene7Video = media.src.startsWith('https://images.dxl.com/is/image/') ? true : false
    const assetURL = isScene7Video && media.src.split('https://images.dxl.com/is/image/')?.[1]

    const el = document.getElementById('s7basiczoom_div')
    if (el) {
      el.innerHTML = ''
    }
    if ((window as Window).s7viewers && el && assetURL && isScene7Video) {
      try {
        new (window as Window).s7viewers.VideoViewer({
          containerId: 's7basiczoom_div',
          params: {
            asset: assetURL,
            serverurl: 'https://images.dxl.com/is/image/',
            contenturl: 'https://images.dxl.com/skins/',
            config: 'Scene7SharedAssets/Universal_HTML5_Video',
            emailurl: 'https://images.dxl.com/s7/emailFriend',
            videoserverurl: 'https://images.dxl.com/is/content/',
          },
        }).init()
      } catch (err) {
        console.log('error', err)
      }
    }
  } else {
    const { serverurl, asset, optionalModifier } = getImageAsset(media.src)
    if (!serverurl || !asset) return null

    const el = document.getElementById('s7basiczoom_div')

    if ((window as Window).s7viewers && el && asset && serverurl) {
      try {
        new (window as Window).s7viewers.BasicZoomViewer({
          containerId: 's7basiczoom_div',
          params: {
            asset,
            serverurl,
            contenturl: CONTENT_URL,
            config: 'Scene7SharedAssets/Universal_HTML5_Zoom_light',
          },
        }).init()
      } catch (err) {
        console.log('error', err)
      }
    }
  }
}

export const Scene7Viewer = ({
  isOpen,
  onClose,
  productVideos,
  productImages,
  initialCurrentImageIndex,
}: Scene7ViewerScriptProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(initialCurrentImageIndex)
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const imagesAndVideos = [...productImages, ...productVideos]

  useEffect(() => {
    const el = document.getElementById('s7basiczoom_div')

    if (el) {
      el.innerHTML = ''
    }

    if (imagesAndVideos && imagesAndVideos[currentImageIndex]?.src) {
      const media = imagesAndVideos[currentImageIndex]

      setTimeout(() => {
        renderMedia(media)
      }, 100)
    }
  }, [isOpen, currentImageIndex])

  const nextPicture = () => {
    if (currentImageIndex === imagesAndVideos.length - 1) return null
    setCurrentImageIndex(currentImageIndex + 1)
  }

  const previousPicture = () => {
    if (currentImageIndex === 0) return null
    setCurrentImageIndex(currentImageIndex - 1)
  }

  if (currentImageIndex === undefined) return null

  const hasPrevious = currentImageIndex !== 0
  const hasNext = currentImageIndex < imagesAndVideos.length - 1

  return (
    <>
      <Head>
        <style>
          {`
            [aria-roledescription="zoomable image"] {
              cursor: zoom-in !important;
            }
          `}
        </style>
      </Head>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={!isMobile}>
        <ModalOverlay />
        <ModalContent
          mx={{ base: 3, lg: 16 }}
          my={{ base: 3, lg: 16 }}
          maxW={'918px'}
          height={'97vh'}
          maxH={{ base: '565px', lg: '946px' }}
          gap={10}
        >
          <ModalHeader
            display={'flex'}
            justifyContent={'center'}
            minH={'48px'}
            borderBottom={'1px solid var(--semantic-surface-border, #E7EAED)'}
            padding={2}
          >
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody zIndex={200} paddingY={0}>
            <Box display={'flex'} height={'100%'} alignItems={'center'}>
              {!isMobile && imagesAndVideos.length > 1 && (
                <Icon
                  position={'relative'}
                  left={'66px'}
                  zIndex={2}
                  as={IoChevronBack}
                  boxSize={12}
                  p={{ base: '8px', lg: '12px' }}
                  backgroundColor="shading.200"
                  borderRadius="base"
                  onClick={previousPicture}
                  cursor={hasPrevious ? 'pointer' : 'not-allowed'}
                  opacity={hasPrevious ? '1' : '0.5'}
                  aria-label="Previous Slide"
                  _hover={{
                    backgroundColor: hasNext ? 'shading.200' : '',
                  }}
                />
              )}

              <Box id="s7basiczoom_div" width={'100%'} cursor={'pointer'} />

              {!isMobile && imagesAndVideos.length > 1 && (
                <Icon
                  position={'relative'}
                  right={'66px'}
                  zIndex={2}
                  as={IoChevronForward}
                  boxSize={12}
                  p={{ base: '8px', lg: '12px' }}
                  backgroundColor="shading.200"
                  borderRadius="base"
                  onClick={nextPicture}
                  cursor={hasNext ? 'pointer' : 'not-allowed'}
                  opacity={hasNext ? '1' : '0.5'}
                  aria-label="Next Slide"
                  _hover={{
                    backgroundColor: hasNext ? 'shading.200' : '',
                  }}
                />
              )}
            </Box>
          </ModalBody>

          <ModalFooter justifyContent={'center'} paddingTop={0} marginTop={'28px'}>
            {imagesAndVideos?.length > 1 && (
              <HStack overflowX="scroll" width="fit-content" alignSelf="center">
                {imagesAndVideos.map((media, index) => {
                  if (media.type === 'video') {
                    return (
                      <Box
                        key={index}
                        position="relative"
                        boxSize={12}
                        cursor="pointer"
                        onClick={() => setCurrentImageIndex(index)}
                      >
                        <ChakraImage
                          key={index}
                          src={media.src}
                          alt={media.alt && media.alt !== '' ? media.alt : `Product image ${index + 1}`}
                          aria-label={media.alt && media.alt !== '' ? media.alt : `Product image ${index + 1}`}
                          cursor="pointer"
                          role={'img'}
                          boxSize={12}
                          objectFit="cover"
                          border="2px solid"
                          borderColor={currentImageIndex === index ? 'text.primary' : 'surface.border'}
                          borderRadius="base"
                          onClick={() => setCurrentImageIndex(index)}
                        />
                        <Box
                          position="absolute"
                          top="0"
                          left="0"
                          width="100%"
                          height="100%"
                          backgroundColor="black"
                          opacity="0.2"
                          zIndex="1"
                        />
                        <Icon
                          as={IoPlayCircleOutline}
                          boxSize={6}
                          color="white"
                          position="absolute"
                          top="50%"
                          left="50%"
                          transform="translate(-50%, -50%)"
                          zIndex="2"
                        />
                      </Box>
                    )
                  } else {
                    return (
                      <ChakraImage
                        key={index}
                        src={media.src}
                        alt={media.alt && media.alt !== '' ? media.alt : `Product image ${index + 1}`}
                        aria-label={media.alt && media.alt !== '' ? media.alt : `Product image ${index + 1}`}
                        cursor="pointer"
                        role={'img'}
                        boxSize={12}
                        objectFit="cover"
                        border="2px solid"
                        borderColor={currentImageIndex === index ? 'text.primary' : 'surface.border'}
                        borderRadius="base"
                        onClick={() => setCurrentImageIndex(index)}
                      />
                    )
                  }
                })}
              </HStack>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
