import { useMemo } from 'react'

export const useGetCartPromotion = (cartPromotions: any, uniqueValidEdsCodes: any) => {
  return useMemo(() => {
    if (!Array.isArray(uniqueValidEdsCodes) || uniqueValidEdsCodes.length === 0) return null

    return cartPromotions?.find(
      (promotion: any) =>
        Array.isArray(promotion?.productPriceGroups) &&
        uniqueValidEdsCodes.some((group) => promotion.productPriceGroups.includes(group)),
    )
  }, [cartPromotions, uniqueValidEdsCodes])
}
