import { Button } from '@chakra-ui/react'

export const ToggleButton = ({
  children,
  value,
  selected,
  changeValue,
}: {
  children: JSX.Element
  value: number
  selected: boolean
  changeValue: (new_val: number) => void
}) => {
  const { onClick, ...rest } = children.props

  const handleClick = () => {
    changeValue(value)
    if (onClick) onClick()
  }
  return (
    <Button
      {...rest}
      variant={'unstyled'}
      isActive={selected}
      overflowWrap={'break-word'}
      onClick={handleClick}
      size={'sm'}
      backgroundColor={selected ? 'white' : 'surface.highlight'}
      borderColor={selected ? 'surface.primary' : 'surface.highlight'}
      borderWidth={1}
      borderRadius={{ base: 'md' }}
      fontSize={14}
      fontWeight={400}
      height={'25px'}
      minWidth={'56px'}
      marginLeft={'0 !important'}
    />
  )
}
