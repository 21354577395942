export type accordionItem = {
  defaultOpen: boolean
  label: string
  content: any
}

export enum SWATCH_VIEW_VALUES {
  GRID = 'grid',
  LIST = 'list',
}
