import { Product } from '@Types/product/Product'

export const useFindPriceByEdsCodes = (childProducts, validEdsCodes) => {
  const prices = []
  const keySet = new Set()

  childProducts?.forEach((product: Product) => {
    if (product?.variants && Array.isArray(product.variants)) {
      product.variants.forEach((variant) => {
        if (variant?.prices && Array.isArray(variant.prices)) {
          variant.prices.forEach((priceObj: any) => {
            const edsCode = priceObj?.custom?.fields?.edsCode
            if (edsCode && validEdsCodes.includes(edsCode)) {
              if (!keySet.has(priceObj?.key)) {
                prices.push(priceObj)
                keySet.add(priceObj?.key)
              }
            }
          })
        }
      })
    }
  })

  const highestPrice = prices.reduce((max, obj) => {
    return obj?.custom?.fields?.basePrice?.centAmount > max?.custom?.fields?.basePrice?.centAmount ? obj : max
  }, prices[0] || {})

  return highestPrice
}
