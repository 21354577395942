import { Alert, AlertDescription, AlertIcon, Box, Flex } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
export const DropShipProductMessage = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  return (
    <Alert
      status="info"
      borderRadius={'md'}
      display="flex"
      justifyContent="space-between"
      bg={'sementic.ui.info-light'}
      py="3"
      pl="4"
      pr="8"
      borderLeftWidth={'4px'}
    >
      <Flex alignItems={'center'} gap={'3'}>
        <AlertIcon aria-hidden="true" w={5} h={5} marginInlineEnd={0} />
        <Box>
          <AlertDescription
            textStyle={'body-75'}
            tabIndex={0}
            aria-label={formatMessage({ id: 'dropShipProduct.message.description' })}
          >
            {formatMessage({ id: 'dropShipProduct.message.description' })}
          </AlertDescription>
        </Box>
      </Flex>
    </Alert>
  )
}
