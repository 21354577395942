import React, { useState } from 'react'
import { Box, Button, Flex, Text, Skeleton } from '@chakra-ui/react'
import { TextSelector } from './text-selector'

const HEMMING_CHARGES_INITIAL_COUNT = 10

interface HemmingChargesSectionProps {
  hemmingChargesPerSegment: string[]
  selectedHemmingCharge: string
  setSelectedHemmingCharge: (value: string) => void
  isLoaded: boolean
  intl: any
  isStoreDataLoading: boolean
}

export const HemmingChargesSection = ({
  hemmingChargesPerSegment,
  selectedHemmingCharge,
  setSelectedHemmingCharge,
  isLoaded,
  intl,
  isStoreDataLoading,
}: HemmingChargesSectionProps) => {
  const [visibleHemmingCharges, setVisibleHemmingCharges] = useState(HEMMING_CHARGES_INITIAL_COUNT)
  const [showMore, setShowMore] = useState(true)

  const toggleHemmingCharges = () => {
    if (showMore) {
      setVisibleHemmingCharges(hemmingChargesPerSegment.length)
    } else {
      setVisibleHemmingCharges(HEMMING_CHARGES_INITIAL_COUNT)
    }
    setShowMore(!showMore)
  }

  return (
    <Skeleton isLoaded={isLoaded}>
      <Flex h={5} mb={3} alignItems={'center'}>
        <Text textStyle={'heading-desktop-75'} pe={2} textTransform={'capitalize'}>
          {intl.formatMessage({
            id: 'product.attribute.inseam',
          })}
        </Text>
        <Text textStyle={'body-75'}>{selectedHemmingCharge}</Text>
      </Flex>

      <Flex gap={2.5} mb={3} flexWrap={'wrap'}>
        {hemmingChargesPerSegment.slice(0, visibleHemmingCharges).map((option, index) => {
          const enhancedOption = {
            value: option,
            inStock: true, // Inseam alteration is a service, not a physical product
          }
          return (
            <Box key={`swatch-${index}`}>
              <TextSelector
                option={enhancedOption}
                isSelected={enhancedOption.value === selectedHemmingCharge}
                isDisabled={false}
                setSelection={() => {
                  setSelectedHemmingCharge(enhancedOption.value)
                }}
                isStoreDataLoading={isStoreDataLoading}
              />
            </Box>
          )
        })}
      </Flex>

      {hemmingChargesPerSegment.length > HEMMING_CHARGES_INITIAL_COUNT && (
        <Box>
          <Button variant="outline" colorScheme="blue" onClick={toggleHemmingCharges}>
            {showMore ? intl.formatMessage({ id: 'search.showMore' }) : intl.formatMessage({ id: 'search.showLess' })}
          </Button>
        </Box>
      )}
    </Skeleton>
  )
}
