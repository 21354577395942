import React from 'react'
import { AlertBox } from 'composable/components/alert-box'

export const AlertMessage = (message, messageType: 'success' | 'warning' | 'info' | 'error' | 'loading', bgColor) => {
  return (
    <AlertBox
      description={message}
      rootProps={{
        bg: bgColor,
        status: messageType,
        width: '100%',
      }}
      closeButtonProps={{ display: 'none' }}
      alertDescriptionProps={{ marginLeft: '15px', marginTop: '2px' }}
    />
  )
}
