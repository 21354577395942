import { useState } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import { Product } from '@Types/product/Product'
import { getProductForQuickView } from 'frontastic/actions/product'
import { filterChildProductsByAvailability } from '../../frontastic/tastics/composable/product-detail-page/helpers'

export interface QuickViewData {
  product: Product
  childProducts?: Product[]
  cartPromotions: any[]
  priceRange: any
  isProductInStock: boolean
}

export const useQuickViewProduct = ({ key }) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ id: `PdpQuickViewModal-${key}` })
  const [loading, setLoading] = useState(false)

  const fetchProductData = async (key: string) => {
    setLoading(true)
    try {
      const response = (await getProductForQuickView(key)) as QuickViewData
      const filteredChildProducts = filterChildProductsByAvailability(response?.childProducts)
      return { ...response, filteredChildProducts: filteredChildProducts }
    } catch (error) {
      console.error('Error fetching product data', error)
    } finally {
      setLoading(false)
    }
  }

  return { isOpen, onOpen, onClose, fetchProductData, loading }
}
