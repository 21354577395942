import { useMemo } from 'react'
import { Cart } from '@Types/cart/Cart'
import { getCartProductByVariantSkuFromAtgCart } from 'composable/helpers/utils/cart-utils'
import { Variant } from '@Types/product/Variant'

type UseAvailableAttributesTextSelectorsParams = {
  availableAttributesOptions: Record<string, string[]>
  cart: Partial<Cart>
  variants: Variant[]
  selectedAttributes: Record<string, string>
}

export const useAvailableAttributesTextSelectors = ({
  availableAttributesOptions,
  cart,
  selectedAttributes,
  variants,
}: UseAvailableAttributesTextSelectorsParams) => {
  const availableAttributesTextSelectors = useMemo(() => {
    const attributesTextSelectors = Object.keys(availableAttributesOptions ?? {})

    return attributesTextSelectors.reduce((accumulator, current) => {
      const options = availableAttributesOptions[current].map((option: string) => {
        const attributeOptionVariant = variants?.find((variant: Variant) => {
          const doAttributesMatch = Object.entries(selectedAttributes).every(([key, value]) => {
            const isOptionCurrentAttribute = key === current
            const variantAttributeValue = variant.attributes[key]

            return isOptionCurrentAttribute ? true : variantAttributeValue === value
          })
          const doesCurrentAttributeMatch = variant.attributes[current] === option
          const currentAttributeCountInCart =
            getCartProductByVariantSkuFromAtgCart({ variantSku: variant?.sku, cart: cart }) ?? 0
          const inStock = variant?.availableQuantity - currentAttributeCountInCart > 0
          return (
            doAttributesMatch &&
            doesCurrentAttributeMatch &&
            (inStock || variant?.availability?.backorderlevel > 0) &&
            variant?.price &&
            variant?.price?.centAmount > 0
          )
        })

        return {
          value: option,
          inStock: !!attributeOptionVariant,
          isBackordered: attributeOptionVariant?.availability?.backorderlevel > 0,
        }
      })

      const attributeValue = selectedAttributes[current]

      return {
        ...accumulator,
        [current]: {
          attribute: {
            label: current,
            value: attributeValue,
          },
          options,
        },
      }
    }, {})
  }, [availableAttributesOptions, cart, variants, selectedAttributes])

  return availableAttributesTextSelectors
}
