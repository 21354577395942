import { FunctionComponent, useMemo } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { buildSchemaForPDP, buildSchemaForPLP } from 'composable/helpers/utils/schemaOrg'

type BreadcrumbData = {
  label: string
  link: string
}

type StructuredDataSchemaProps =
  | {
      data: {
        description: string
        title: string
        breadcrumbs: BreadcrumbData[]
      }
      type: 'pdp'
    }
  | {
      data: {
        breadcrumbs: BreadcrumbData[]
      }
      type: 'plp'
    }

export const StructuredDataSchema: FunctionComponent<StructuredDataSchemaProps> = ({ data, type }) => {
  const router = useRouter()

  const structuredData = useMemo(() => {
    switch (type) {
      case 'pdp':
        return buildSchemaForPDP({
          breadcrumbs: data.breadcrumbs.map((item, index) => ({
            label: item.label,
            link: item.link || router.asPath.split('?')[0],
          })),
          description: data.description,
          title: data.title,
        })
      case 'plp':
        return buildSchemaForPLP({
          breadcrumbs: data.breadcrumbs.map((item, index) => ({
            label: item.label,
            link: item.link || router.asPath.split('?')[0],
          })),
        })
    }
  }, [data, router.asPath, type])

  if (!structuredData) {
    return null
  }

  return (
    <Head>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }} />
    </Head>
  )
}
