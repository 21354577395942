import React, { useEffect, useState, useRef } from 'react'
import { handleClientScriptLoad } from 'next/script'
import { Box, Container, Text } from '@chakra-ui/react'
import {
  FUNCTIONAL_COOKIE_CATEGORY,
  POWERREVIEWS_API_KEY,
  POWERREVIEWS_MERCHANT_GROUP_ID,
  POWERREVIEWS_MERCHANT_ID,
} from 'composable/components/general/constants'
import { IoCloseOutline } from 'react-icons/io5'
import { getScriptType } from '../../../../helpers/utils/appendScript'

export const CustomerReviews = (props) => {
  const [reviewCountFromData, setReviewCountFromData] = useState(null)
  const [reviewCountPopup, setReviewCountPopup] = useState(0)
  const [isShow, setIsShow] = useState(false)
  let [productReviewStars, setProductReviewStars] = useState(null)

  const body50Style = {
    fontFamily: 'Libre Franklin',
    fontWeight: '400',
    lineHeight: '150%',
    letterSpacing: '0',
    textIndent: '0px',
    textTransform: 'none',
    textDecoration: 'none',
    fontSize: '0.75rem',
    color: 'var(--chakra-colors-semantic-text-primary)',
  }

  useEffect(() => {
    try {
      if (typeof (window as any)?.POWERREVIEWS == 'undefined') {
        handleClientScriptLoad({
          src: '//ui.powerreviews.com/stable/4.1/ui.js',
          id: 'powerReviewScript',
          className: getScriptType(FUNCTIONAL_COOKIE_CATEGORY),
        })
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  const addEventListeners = () => {
    const starsContainers = document.querySelectorAll('.pr-snippet-stars-container')
    const readAndWriteContainers = document.querySelectorAll('.pr-snippet-read-and-write')

    starsContainers.forEach((element) => {
      element.addEventListener('click', () => {
        setIsShow(true)
      })
      element.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          setIsShow(true)
          setTimeout(() => {
            let targetele = document.querySelector<HTMLElement>(
              '#pr-reviewSnapShot-popup .pr-review-snapshot-snippets-headline',
            )
            let closebutton = document.querySelector<HTMLElement>('#pr-reviewSnapShot-popup #popupclosebtn')
            let targeAfterClose = document.querySelector<HTMLElement>(
              '#pr-reviewSnapShot-popup .pr-review-snapshot-snippets-headline',
            )
            if (targetele) {
              targetele?.setAttribute('tabindex', '0')
              targetele?.focus()
            }
          }, 500)
        }
      })
    })

    if (readAndWriteContainers.length) {
      readAndWriteContainers[0].addEventListener('click', scrollToReview)
    }
  }

  const applyStyles = () => {
    const reviewCount = document.querySelector('#pr-reviewsnippet .pr-snippet-review-count') as HTMLElement
    const recommendationText = document.querySelector('#pr-reviewsnippet .pr-snippet-reco-to-friend') as HTMLElement

    if (reviewCount) {
      Object.assign(reviewCount.style, body50Style)
    }

    if (recommendationText) {
      Object.assign(recommendationText.style, body50Style)
    }
  }

  useEffect(() => {
    initializePowerReviews()

    const intervalClass = setInterval(() => {
      if (document.querySelector('.pr-snippet-stars-container')) {
        clearInterval(intervalClass)
        addEventListeners()
        applyStyles()
      }
    }, 200)

    return () => {
      const starsContainers = document.querySelectorAll('.pr-snippet-stars-container')
      const readAndWriteContainers = document.querySelectorAll('.pr-snippet-read-and-write')

      starsContainers.forEach((element) => {
        element.removeEventListener('click', () => {
          setIsShow(false)
        })
      })
    }
  }, [props.product.productId])

  let powerReviewsCommonObj = {
    api_key: POWERREVIEWS_API_KEY,
    locale: 'en_US',
    merchant_group_id: POWERREVIEWS_MERCHANT_GROUP_ID,
    Enable_Content_Collection_Modal: true,
    merchant_id: POWERREVIEWS_MERCHANT_ID,
    page_id: props.product.key,
    REVIEW_DISPLAY_SNAPSHOT_TYPE: 'SIMPLE',
    ENABLE_REVIEW_FILTERING: 1,
    REVIEW_FILTERING_TYPE: 'DROPDOWNS',
  }

  const initializePowerReviews = () => {
    if (typeof (window as any).POWERREVIEWS !== 'undefined' && (window as any).POWERREVIEWS.display) {
      ;(window as any).pwr('unmount', 'pr-reviewsnippet')
    }
    ;(window as any).pwr =
      (window as any).pwr ||
      function () {
        ;((window as any).pwr.q = (window as any).pwr.q || []).push(arguments)
      }

    const powerReviewsCheckInterval = setInterval(() => {
      if (typeof (window as any).POWERREVIEWS !== 'undefined') {
        clearInterval(powerReviewsCheckInterval)
        const finalObj = Object.assign(
          {
            components: {
              ReviewSnippet: 'pr-reviewsnippet',
              ReviewDisplay: 'pr-reviewSnapShot-popup',
            },
            on_render: (config, data) => {
              const reviewLink = document.querySelector<HTMLElement>('#pr-reviewsnippet .pr-snippet-review-count')
              if (data) {
                setReviewCountFromData(data.review_count)
              }
              if (starContainer && !starContainer.querySelector('.pr-rating-stars')) {
                let reviewCount = document.querySelector<HTMLElement>('#pr-reviewsnippet .pr-snippet-review-count')
                let reviewStars = document.querySelector<HTMLElement>('#pr-reviewsnippet .pr-rating-stars')
                const decimalReviewStars = document.querySelector('pr-snippet-rating-decimal').innerHTML

                if (reviewCount && reviewStars) {
                  let reviewCountsNumber
                  reviewCountsNumber = parseInt(reviewCount.innerText, 10)
                  const hasNoReview = isNaN(reviewCountsNumber) || reviewCountsNumber == 0 ? true : false
                  productReviewStars = decimalReviewStars
                  if (document.querySelector('.pr-snippet-rating-decimal')) {
                    setProductReviewStars(document.querySelector('.pr-snippet-rating-decimal').innerHTML)
                  }
                  starContainer.innerHTML = ''
                  starContainer.appendChild(reviewStars.cloneNode(true))
                }
              }
              if (reviewLink) {
                if (reviewLink.innerText.indexOf('No Reviews') > -1) {
                  reviewLink.innerText = 'Write the first review'
                }
                reviewLink.removeAttribute('href')
              }

              let rEle = document.querySelector('#pr-reviewsnippet .pr-snippet-reco-to-friend')
              let cusRec = ''
              if (rEle) {
                rEle.setAttribute('tabindex', '0')
                rEle.setAttribute('aria-label', cusRec + ' Recommended')
              }

              let rnStart = document.querySelector('#pr-reviewsnippet .pr-snippet-stars-container .pr-snippet-stars')
              if (rnStart) {
                rnStart.setAttribute('tabindex', '0')
              }

              let rnLink = document.querySelector<HTMLElement>('#pr-reviewsnippet a.pr-snippet-review-count')
              if (rnLink) {
                rnLink.setAttribute('tabindex', '0')
                rnLink.setAttribute('aria-label', rnLink.innerText)
              }
              let rnLinkSpan = document.querySelector('#pr-reviewsnippet  span.pr-snippet-review-count')
              if (rnLinkSpan) {
                rnLinkSpan.setAttribute('tabindex', '0')
                rnLinkSpan.setAttribute('aria-label', 'Write the first review')
              }

              /*******************************Popup******************************* */
              const starpopupContainer = document.querySelector('#pr-reviewSnapShot-popup .p-w-r')
              if (starpopupContainer) {
                const reviewPopupCount = document.querySelector<HTMLElement>(
                  '#pr-review-snapshot .pr-snippet-review-count',
                )
                const reviewRatings = document.querySelector<HTMLElement>(
                  '#pr-review-snapshot .pr-review-snapshot-snippets-headline',
                )

                if (reviewPopupCount) {
                  setReviewCountPopup(parseInt(reviewPopupCount.innerText, 10))
                }
              }
              const reviewHistogramCount5starElePopup = document.querySelector(
                '#pr-reviewSnapShot-popup .pr-ratings-histogram-list-item .pr-histogram-5Stars',
              )
              const reviewHistogramCount4starElePopup = document.querySelector(
                '#pr-reviewSnapShot-popup .pr-ratings-histogram-list-item .pr-histogram-4Stars',
              )
              const reviewHistogramCount3starElePopup = document.querySelector(
                '#pr-reviewSnapShot-popup .pr-ratings-histogram-list-item .pr-histogram-3Stars',
              )
              const reviewHistogramCount2starElePopup = document.querySelector(
                '#pr-reviewSnapShot-popup .pr-ratings-histogram-list-item .pr-histogram-2Stars',
              )
              const reviewHistogramCount1starElePopup = document.querySelector(
                '#pr-reviewSnapShot-popup .pr-ratings-histogram-list-item .pr-histogram-1Stars',
              )

              if (
                reviewHistogramCount5starElePopup &&
                reviewHistogramCount4starElePopup &&
                reviewHistogramCount3starElePopup &&
                reviewHistogramCount2starElePopup &&
                reviewHistogramCount1starElePopup
              ) {
                let ele5 = reviewHistogramCount5starElePopup.querySelector<HTMLElement>(
                  '.pr-ratings-histogram-barValue',
                )
                let e5
                let num5
                if (ele5) {
                  e5 = ele5.style.width
                  e5 = e5.replace('%', ' ')
                  num5 = Math.round(e5)
                  if (num5) {
                    num5 = num5
                  } else {
                    num5 = 0
                  }
                } else {
                  num5 = 0
                }

                let reviewCountEle5 =
                  reviewHistogramCount5starElePopup.querySelector<HTMLElement>('.pr-histogram-count')
                if (reviewCountEle5) {
                  reviewCountEle5.innerText = num5 + '%'
                }
                let ele4 = reviewHistogramCount4starElePopup.querySelector<HTMLElement>(
                  '.pr-ratings-histogram-barValue',
                )
                let e4
                let num4
                if (ele4) {
                  e4 = ele4.style.width
                  e4 = e4.replace('%', ' ')
                  num4 = Math.round(e4)
                  if (num4) {
                    num4 = num4
                  } else {
                    num4 = 0
                  }
                } else {
                  num4 = 0
                }

                let reviewCountEle4 =
                  reviewHistogramCount4starElePopup.querySelector<HTMLElement>('.pr-histogram-count')
                if (reviewCountEle4) {
                  reviewCountEle4.innerText = num4 + '%'
                }
                let ele3 = reviewHistogramCount3starElePopup.querySelector<HTMLElement>(
                  '.pr-ratings-histogram-barValue',
                )
                let e3
                let num3
                if (ele3) {
                  e3 = ele3.style.width
                  e3 = e3.replace('%', ' ')
                  num3 = Math.round(e3)
                  if (num3) {
                    num3 = num3
                  } else {
                    num3 = 0
                  }
                } else {
                  num3 = 0
                }

                let reviewCountEle3 =
                  reviewHistogramCount3starElePopup.querySelector<HTMLElement>('.pr-histogram-count')
                if (reviewCountEle3) {
                  reviewCountEle3.innerText = num3 + '%'
                }

                let ele2 = reviewHistogramCount2starElePopup.querySelector<HTMLElement>(
                  '.pr-ratings-histogram-barValue',
                )
                let e2
                let num2
                if (ele2) {
                  e2 = ele2.style.width
                  e2 = e2.replace('%', ' ')
                  num2 = Math.round(e2)
                  if (num2) {
                    num2 = num2
                  } else {
                    num2 = 0
                  }
                } else {
                  num2 = 0
                }

                let reviewCountEle2 =
                  reviewHistogramCount2starElePopup.querySelector<HTMLElement>('.pr-histogram-count')
                if (reviewCountEle2) {
                  reviewCountEle2.innerText = num2 + '%'
                }

                let ele1 = reviewHistogramCount1starElePopup.querySelector<HTMLElement>(
                  '.pr-ratings-histogram-barValue',
                )
                let e1
                let num1
                if (ele1) {
                  e1 = ele1.style.width
                  e1 = e1.replace('%', ' ')
                  num1 = Math.round(e1)
                  if (num1) {
                    num1 = num1
                  } else {
                    num1 = 0
                  }
                } else {
                  num1 = 0
                }

                let reviewCountEle1 =
                  reviewHistogramCount1starElePopup.querySelector<HTMLElement>('.pr-histogram-count')
                if (reviewCountEle1) {
                  reviewCountEle1.innerText = num1 + '%'
                }
              }

              let overallSize = document.querySelector<HTMLElement>(
                '#pr-reviewSnapShot-popup .pr-review-snapshot-msq-container',
              )
              //ADA Changes for brand section and oversize
              let overallSizeHeader = document.querySelectorAll<HTMLElement>(
                '#reviewTabs .pr-histogram-msq-headline,#reviewTabs .pr-histogram-msq-headline .pr-histogram-msq-headline',
              )
              let overallSizeHeaderSizeTextMin = document.querySelectorAll<HTMLElement>(
                '#reviewTabs .pr-slider-text-above .pr-min-text',
              )
              let overallSizeHeaderSizeTextMax = document.querySelectorAll<HTMLElement>(
                '#reviewTabs .pr-slider-text-above .pr-max-text',
              )
              adaAddAttr(overallSizeHeader)
              adaAddAttr(overallSizeHeaderSizeTextMin)
              adaAddAttr(overallSizeHeaderSizeTextMax)
              let brandscore = document.querySelectorAll<HTMLElement>('#reviewTabs .pr-review-snapshot-brandscore')
              if (brandscore) {
                let brandscoreHeader = document.querySelector<HTMLElement>(
                  '#reviewTabs .pr-review-snapshot-brandscore .pr-brandscore-headline',
                )
                let brandscoreDescription = document.querySelector<HTMLElement>(
                  '#reviewTabs .pr-review-snapshot-brandscore .pr-brandscore-description',
                )
                let brandscoreScore = document.querySelector<HTMLElement>(
                  '#reviewTabs .pr-review-snapshot-brandscore .pr-accessible-text',
                )
                if (brandscoreHeader) {
                  brandscoreHeader.setAttribute('tabindex', '0')
                  brandscoreHeader.setAttribute('aria-label', brandscoreHeader.innerText)
                }
                if (brandscoreDescription) {
                  brandscoreDescription.setAttribute('tabindex', '0')
                  brandscoreDescription.setAttribute('aria-label', brandscoreDescription.innerText)
                }
                if (brandscoreScore) {
                  brandscoreScore.setAttribute('tabindex', '0')
                  brandscoreScore.setAttribute('aria-label', brandscoreScore.innerText)
                }
              }
              let reviewedBy = document.querySelectorAll<HTMLElement>('.pr-rd-main-header-search .reviewedBy>h2')
              let revieDesiplay = document.querySelectorAll<HTMLElement>(
                '#pr-review-display .pr-rd-header .pr-snippet-stars',
              )
              let revieDesiplayHeadline = document.querySelectorAll<HTMLElement>(
                '#pr-review-display .pr-rd-header .pr-rd-review-headline',
              )
              let revieDesiplayDescription = document.querySelectorAll<HTMLElement>(
                '#pr-review-display .pr-rd-description .pr-rd-description-text',
              )
              let revieDesiplayAction = document.querySelectorAll<HTMLElement>(
                '#pr-review-display .pr-rd-helpful-action-group .pr-rd-helpful-text',
              )
              if (revieDesiplay) {
                revieDesiplay.forEach(function (element) {
                  element.setAttribute('tabindex', '0')
                })
              }
              let reviedDescriptionblock = document.querySelectorAll<HTMLElement>(
                '#pr-review-display .pr-rd-description .pr-rd-side-content-block p.pr-rd-details,#pr-review-display .pr-rd-description .pr-rd-side-content-block p.pr-rd-review-disclosure',
              )

              adaAddAttr(reviedDescriptionblock)
              adaAddAttr(reviewedBy)
              adaAddAttr(revieDesiplayHeadline)
              adaAddAttr(revieDesiplayDescription)
              adaAddAttr(revieDesiplayAction)
            },
          },
          { ...powerReviewsCommonObj },
        )

        ;(window as any).pwr('render', { ...finalObj })
      }
      const starContainer = document.querySelector('#pr-stars .p-w-r')
      const getRec = document.querySelector('.pr-review-snapshot-recomend .pr-reco-value')
      const reviewLink = document.querySelector('#pr-reviewsnippet .pr-snippet-review-count')
      if (starContainer && !starContainer.querySelector('.pr-rating-stars')) {
        let reviewCount = document.querySelector<HTMLElement>('#pr-reviewsnippet .pr-snippet-review-count')
        let reviewStars = document.querySelector<HTMLElement>('#pr-reviewsnippet .pr-rating-stars')
        if (reviewCount && reviewStars) {
          productReviewStars = parseInt(reviewStars.innerText, 10)
          starContainer.innerHTML = ''
          starContainer.appendChild(reviewStars.cloneNode(true))
        }
      }
    }, 1000)
  }

  const adaAddAttr = (selector) => {
    if (selector) {
      selector.forEach(function (element) {
        element.setAttribute('tabindex', '0')
        element.setAttribute('aria-label', element.innerText)
        if (element.ariaHidden === 'true') {
          element.setAttribute('aria-hidden', 'false')
        }
      })
    }
  }
  const scrollToReview = (event) => {
    event.stopPropagation()
    event.preventDefault()
    let ele = document.getElementById('reviewTabs')
    if (ele) {
      setIsShow(false)
      ele.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const scrollToReviewEnter = (event) => {
    if (event.key === 'Enter') {
      scrollToReview(event)
    }
  }
  const handleScroll = (e, handler) => {
    if (
      e?.target?.className == 'pr-snippet-review-count' ||
      (e?.target?.nodeName === 'A' && e?.target?.className == 'pr-snippet-review-count')
    ) {
      handler(e)
    }
  }
  const handelOnClosekey: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    closeButtonRef.current = document.querySelector('#closebutton')
    targerCountRef.current = document.querySelector('.pr-snippet-review-count')
    if (event.key === 'Enter') {
      event.preventDefault()
      if (closeButtonRef.current) {
        setIsShow(false)
        targerCountRef.current.focus()
      }
    }
  }
  const handelOnClose = () => {
    setIsShow(false)
  }
  const closeButtonRef = useRef<HTMLDivElement | null>(null)
  const targerCountRef = useRef<HTMLDivElement | null>(null)

  const showAll = 'Show all reviews ' + reviewCountPopup

  useEffect(() => {
    const checkElements = setInterval(() => {
      const recommendationElement = document.querySelector('#pr-reviewsnippet .pr-snippet-reco-to-friend')

      if (recommendationElement) {
        const percentageSpan = recommendationElement.querySelector('.pr-snippet-reco-to-friend-percent')
        const messageSpan = recommendationElement.querySelector('.pr-reco-to-friend-message')
        const percentageText = percentageSpan ? percentageSpan.textContent : ''
        const messageText = messageSpan ? messageSpan.textContent : 'Recommended'

        // Now construct the aria-label combining both texts
        recommendationElement.setAttribute('tabindex', '0')
        recommendationElement.setAttribute('aria-label', `${percentageText} ${messageText}`)

        clearInterval(checkElements)
      }
    }, 200)

    return () => clearInterval(checkElements)
  }, [])

  return (
    <>
      <Container marginLeft={0} p={0}>
        <Box id="pr-reviewsnippet" onClick={(e) => handleScroll(e, scrollToReview)} />
      </Container>

      <Container id="prPopup" margin={0} style={{ display: isShow && reviewCountFromData ? 'block' : 'none' }}>
        <Container
          bg={'#fff'}
          position={'absolute'}
          shadow={'md'}
          top={{ md: 'auto' }}
          width={{ base: 'calc(100% - 2rem)', sm: 'calc(100% - 2rem)', md: '400px', lg: '400px' }}
          maxWidth={{ base: 'sm:100%', sm: '100%' }}
          zIndex={333}
          padding={{ base: '0px', md: '0 15px' }}
          left={'1px'}
          _before={{
            content: '""',
            position: 'absolute',
            height: '15px',
            width: '15px',
            transform: 'rotate(45deg)',
            borderLeft: '1px solid #E2E8F0',
            borderTop: '1px solid #E2E8F0',
            top: '-8px',
            bg: '#fff',
            left: 4,
          }}
        >
          <Box id="pr-reviewSnapShot-popup" tabIndex={0}></Box>
          <Box textAlign={'center'} marginBottom={8} textDecoration={'underline'} _hover={{ textDecoration: 'none' }}>
            {reviewCountPopup && reviewCountPopup > 0 && (
              <Text
                id="closebutton"
                onClick={(event) => {
                  scrollToReview(event)
                }}
                onKeyDown={(event) => {
                  scrollToReviewEnter(event)
                }}
                tabIndex={0}
                aria-label={showAll}
                cursor={'pointer'}
              >
                Show all reviews ({reviewCountPopup})
              </Text>
            )}
          </Box>
          <Box
            position={'absolute'}
            top={'10px'}
            right={'10px'}
            tabIndex={0}
            aria-label={'Close Button'}
            onClick={handelOnClose}
            onKeyDown={handelOnClosekey}
            id="popupclosebtn"
          >
            <IoCloseOutline fontSize={20} cursor={'pointer'} />
          </Box>
        </Container>
      </Container>
    </>
  )
}
