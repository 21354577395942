import { Badge as ChakraBadge, BadgeProps as ChakraBadgeProps } from '@chakra-ui/react'

export interface BadgeProps extends ChakraBadgeProps {
  value: string
  variant?: BadgeVariants
  size?: BadgeSizes | { base: BadgeSizes; lg: BadgeSizes }
}

export type BadgeVariants = 'solid' | 'subtle' | 'outline' | 'solidWhite' | 'solidRed'

export type BadgeSizes = 'small' | 'large'

export const Badge = ({ value, variant = 'solid', size = 'large', ...chakraProps }: BadgeProps) => {
  return (
    <ChakraBadge variant={variant} size={size} {...chakraProps}>
      {value}
    </ChakraBadge>
  )
}
