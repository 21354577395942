import { useEffect } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody } from '@chakra-ui/react'
import { Product } from '@Types/product/Product'
import QuickViewProductDetailsLayout from './pdp-quick-view-layout'

interface PdpQuickViewModalProps {
  onClose: () => void
  isOpen: boolean
  data: QuickViewData
  algoliaQueryId?: string
}

interface QuickViewData {
  product: Product
  childProducts?: Product[]
  filteredChildProducts?: Product[]
  cartPromotions: any[]
  priceRange: any
  isProductInStock: boolean
}

const PDPQuickViewModal = ({ data, isOpen, onClose, algoliaQueryId }: PdpQuickViewModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <Modal isCentered closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={'890px'} borderRadius={8}>
        <ModalCloseButton />

        <ModalBody pt={12} pb={8}>
          <QuickViewProductDetailsLayout {...data} algoliaQueryId={algoliaQueryId} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PDPQuickViewModal
