interface ImageAssetProps {
  serverurl: string
  asset: string
  optionalModifier: string | null
}

export const CONTENT_URL = 'https://images.dxl.com/skins/'

export const getImageAsset = (url: string): ImageAssetProps => {
  const regex = /^(https?:\/\/[^\/]+\/is\/image\/)([^?]+)\??(.*)$/
  const match = url.match(regex)

  if (match && match.length >= 3) {
    const serverurl = match[1]
    const asset = match[2]
    const optionalModifier = match[3] || null
    return { serverurl, asset, optionalModifier }
  } else {
    return { serverurl: '', asset: '', optionalModifier: null }
  }
}
