import React, { useEffect, useRef, useState } from 'react'
import Script from 'next/script'
import { AFTERPAY_MESSAGING_SCRIPT_SRC } from 'composable/components/general/constants'

const AfterPayMessaging = ({
  mpid,
  placementId,
  amount,
  currency,
  consumerLocale,
  itemSkus,
  priceRange,
  isEligible,
}) => {
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (scriptLoaded && containerRef.current) {
      const element = document.createElement('square-placement')
      element.setAttribute('data-mpid', mpid)
      element.setAttribute('data-placement-id', placementId)
      element.setAttribute('data-page-type', 'product')
      element.setAttribute('data-amount', priceRange ? 0 : amount)
      element.setAttribute('data-currency', currency)
      element.setAttribute('data-consumer-locale', consumerLocale)
      element.setAttribute('data-item-skus', itemSkus)
      element.setAttribute('data-is-eligible', isEligible)
      containerRef.current.appendChild(element)
      return () => {
        if (containerRef.current) containerRef.current.removeChild(element)
      }
    }
  }, [scriptLoaded, mpid, placementId, amount, currency, consumerLocale, itemSkus, isEligible])

  return (
    <>
      <div ref={containerRef} tabIndex={0}></div>
      <Script async={true} src={AFTERPAY_MESSAGING_SCRIPT_SRC} onReady={() => setScriptLoaded(true)}></Script>
    </>
  )
}

export default AfterPayMessaging
