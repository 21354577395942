/* eslint-disable react-hooks/exhaustive-deps */
import { useProduct } from 'frontastic'
import { useState, useEffect } from 'react'

interface HemmingChargesPerSegmentProps {
  isHemmingChargeable: boolean
  selectedAttributes: { [key: string]: any }
}

interface HemmingCharges {
  chargeAmount: number
  charges: {
    Long: string[]
    Reg: string[]
    Short: string[]
  }
  taxCode: string
}

export const useHemmingChargesPerSegment = ({
  isHemmingChargeable,
  selectedAttributes,
}: HemmingChargesPerSegmentProps) => {
  const [hemmingCharges, setHemmingCharges] = useState<HemmingCharges>(undefined)
  const [hemmingChargesPerSegment, setHemmingChargesPerSegment] = useState<string[]>(undefined)
  const { getAllHemmingCharges } = useProduct()

  const fetchHemmingCharges = async () => {
    try {
      const charges = await getAllHemmingCharges()
      setHemmingCharges(charges)
    } catch (error) {
      console.error('Error fetching hemming charges:', error)
    }
  }

  useEffect(() => {
    if (isHemmingChargeable) {
      fetchHemmingCharges()
    }
  }, [getAllHemmingCharges, isHemmingChargeable])

  useEffect(() => {
    if (Object.keys(selectedAttributes).length === 0 || !hemmingCharges) {
      setHemmingChargesPerSegment(undefined)
    } else if (selectedAttributes?.segment3) {
      let selectedSegment3 = selectedAttributes.segment3

      if (selectedSegment3 === 'R') {
        selectedSegment3 = 'Reg'
      } else if (selectedSegment3 === 'S') {
        selectedSegment3 = 'Short'
      } else if (selectedSegment3 === 'L') {
        selectedSegment3 = 'Long'
      }
      /* Values from Custom-object "Hemming-charges" are in below format
        {
          "Long": [...values ],
          "Reg": [...values ],
          "Short": [... values ]
        }
      */

      setHemmingChargesPerSegment(hemmingCharges?.charges?.[selectedSegment3])
    }
  }, [selectedAttributes])

  return { hemmingChargesPerSegment }
}
