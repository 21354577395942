import { Button, Text } from '@chakra-ui/react'
import { TextSelectorProps } from '../../types'

export const TextSelector = ({
  option,
  setSelection,
  isDisabled,
  isSelected,
  isFocusable,
  isStoreDataLoading,
}: TextSelectorProps) => {
  const { value } = option

  // Separate logic for isDisabled and isStoreDataLoading styles
  const isButtonDisabled = isDisabled
  const isLoading = isStoreDataLoading

  return (
    <Button
      key={value}
      p={3}
      minW={12}
      w={'auto'}
      h={'auto'}
      aria-label={value}
      aria-selected={isSelected}
      backgroundColor={isLoading ? 'surface.loading' : 'surface.primary'} // Different background when loading
      borderRadius={'4px'}
      border={'1px solid'}
      borderColor={isSelected ? 'brand.primary' : isLoading ? 'shading.loading' : 'shading.300'} // Different border when loading
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
        if (!isButtonDisabled && !isLoading) {
          setSelection(value) // Prevent selection if disabled or loading
        }
      }}
      isDisabled={isButtonDisabled || isLoading} // Both loading and disabled states considered
      cursor={isButtonDisabled || isLoading ? 'not-allowed' : 'pointer'}
      color={isLoading ? 'text.loading' : 'text.primary'} // Different text color when loading
      _hover={{
        borderColor: isSelected ? 'brand.primary' : isLoading ? 'primary.loading' : 'primary.300', // Custom hover for loading
      }}
      _focus={{
        borderColor: isFocusable ? 'info.500' : 'brand.primary',
      }}
      _active={{
        borderColor: 'primary.400',
      }}
      _disabled={{
        borderColor: isButtonDisabled ? 'surface.muted' : 'shading.300', // Apply disabled styles
        backgroundColor: isButtonDisabled ? 'surface.muted' : 'surface.primary',
        color: isButtonDisabled ? 'text.muted' : 'text.primary',
      }}
    >
      <Text textStyle={'body-100'}>{value}</Text>
    </Button>
  )
}
