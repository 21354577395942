import React, { useEffect, useRef, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Box,
  ModalCloseButton,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { ContentstackRichText } from '../contentstack/rich-text'
import useContentstack from '../hooks/useContentstack'

const COMPONENT_RICH_TEXT = 'rich_text'

const SizeChartModalComponent = ({ isOpen, onClose, slug, messageID, brandName }) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { getEntryBySlug, isLoading } = useContentstack()
  const [responseData, setResponseData] = useState(null)
  const hasFetched = useRef(false)
  const modalSize = useBreakpointValue({ base: 'full', md: 'lg' })
  const [isBrandSpecificModal, setIsBrandSpecificModal] = useState(false)
  useEffect(() => {
    const fetchContent = async () => {
      const [genericSlug, brandSpecificSlug] = slug
      const data = await getEntryBySlug(COMPONENT_RICH_TEXT, brandSpecificSlug)
      if (!!data) {
        setResponseData(data)
        setIsBrandSpecificModal(true)
      } else {
        const data = await getEntryBySlug(COMPONENT_RICH_TEXT, genericSlug)
        setResponseData(data)
        setIsBrandSpecificModal(false)
      }
    }

    if (isOpen && !hasFetched.current) {
      fetchContent()
      hasFetched.current = true
    }
  }, [getEntryBySlug, isOpen, slug])

  useEffect(() => {
    if (!isOpen) {
      hasFetched.current = false
    }
  }, [isOpen])

  return (
    <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay />
      <ModalContent width={'64rem !important'} mx={2} my={4}>
        <Flex justifyContent="center" alignItems="center" position="relative" borderBottom="1px solid #E1E5E8">
          <ModalHeader fontSize="16px" textAlign="center" tabIndex={0} fontWeight={'600'} color="#212529" flex="1">
            {isBrandSpecificModal
              ? formatMessage({ id: messageID, defaultMessage: 'Size Chart', values: { brandName } })
              : formatMessage({ id: messageID, defaultMessage: 'Size Chart', values: { brandName: '' } })}
          </ModalHeader>
          <ModalCloseButton
            size={'lg'}
            right="8px"
            _hover={{ backgroundColor: 'none !important', opacity: '0.7 !important' }}
          />
        </Flex>
        <Box>{responseData && <ContentstackRichText {...responseData} padding="24px" />}</Box>
      </ModalContent>
    </Modal>
  )
}
const SizeChartModal = React.memo(SizeChartModalComponent)

export default SizeChartModal
