import { HStack, StackProps, StyleProps, Text, TextProps } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { Badge } from './badge'

interface PriceProps {
  priceProps?: TextProps
  price: string
  rootProps?: StackProps
  basePrice?: string
  discountPercent?: number
  discountedPriceProps?: TextProps
  promoPrice?: string
  priceRange?: any
  stackStyle?: StyleProps
}

export const FlatPrice = ({
  priceProps,
  price,
  basePrice,
  discountedPriceProps,
  discountPercent,
  stackStyle,
}: PriceProps) => {
  const intl = useFormat({ name: 'common' })

  if (!price) return null

  return (
    <HStack gap={1} {...stackStyle}>
      {basePrice && (
        <Text
          as="span"
          textStyle={'figma-strikethrough-75'}
          color={'text-muted'}
          textDecoration={'line-through'}
          {...discountedPriceProps}
          tabIndex={0}
          aria-label={`Original price ${basePrice}`}
        >
          {basePrice}
        </Text>
      )}

      <Text
        as="span"
        textStyle={'body-75'}
        color={'text'}
        tabIndex={0}
        aria-label={basePrice && price ? `discounted price ${price}` : price}
        {...priceProps}
      >
        {price}
      </Text>

      {basePrice && discountPercent && (
        <Badge
          value={intl.formatMessage({ id: 'product.discount.label', values: { discountPercent } })}
          variant={'solidRed'}
          size={{ base: 'small', lg: 'small' }}
          textTransform="none"
          borderRadius={4}
          fontSize={'12px'}
          lineHeight={'14.4px'}
          fontWeight={600}
          tabIndex={0}
          aria-label={intl.formatMessage({ id: 'product.discount.label', values: { discountPercent } })}
        />
      )}
    </HStack>
  )
}
