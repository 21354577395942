import { useState } from 'react'
import { Button, ButtonGroup } from '@chakra-ui/react'
import type { ButtonGroupProps } from '@chakra-ui/react'
import { ToggleButton } from './ToggleButtonComponent'

export interface ToggleButtonGroupProps extends Omit<ButtonGroupProps, 'onChange'> {
  children: JSX.Element | JSX.Element[]
  initialIndex?: number
  allowNone?: boolean
  exclusive?: boolean
  onChange?: (v: (string | number | JSX.Element)[] | null) => any
}

export const ToggleButtonGroup = (props: ToggleButtonGroupProps): JSX.Element => {
  const { children, initialIndex, onChange, allowNone = true, exclusive = true, ...rest } = props

  const defaultIndex = initialIndex ?? (allowNone ? undefined : 0)
  const [currButton, setCurrButton] = useState<Set<number | undefined>>(
    defaultIndex === undefined ? new Set() : new Set([defaultIndex]),
  )

  const getValue = (e: JSX.Element): string | number | JSX.Element => {
    if (e?.props?.value) {
      return e.props.value
    } else if (typeof e?.props?.children === 'string') {
      return e.props.children
    } else {
      return e
    }
  }

  const getSelectedValues = (selected: Set<number | undefined>): (string | number | JSX.Element)[] | null => {
    if (selected.size < 1) return null
    if (Array.isArray(children)) {
      return [...selected].map((idx) => getValue(children[idx as number]))
    } else {
      return [getValue(children)]
    }
  }

  const handleValueChange = (idx: number) => {
    if (currButton.has(idx)) {
      const cannotAllowNoneAndOneInSet = !allowNone && currButton.size < 2

      if (cannotAllowNoneAndOneInSet) return

      setCurrButton((prev) => {
        prev.delete(idx)

        if (onChange) onChange(getSelectedValues(prev))

        return new Set(prev)
      })
    } else {
      const exclusiveAndOneInSet = exclusive && currButton.size < 2

      if (exclusiveAndOneInSet) {
        const s = new Set([idx])

        setCurrButton(s)

        if (onChange) onChange(getSelectedValues(s))
      } else {
        setCurrButton((prev) => {
          const s = prev.add(idx)

          if (onChange) onChange(getSelectedValues(s))

          return new Set(s)
        })
      }
    }
  }

  return (
    <ButtonGroup
      backgroundColor="surface.highlight"
      borderRadius={{ base: 'md' }}
      padding={{ base: 0.5 }}
      height={'29px'}
      {...rest}
    >
      {Array.isArray(children) ? (
        children.map((element, idx) => (
          <ToggleButton key={idx} value={idx} selected={currButton.has(idx)} changeValue={handleValueChange}>
            {element}
          </ToggleButton>
        ))
      ) : (
        <ToggleButton value={0} selected={currButton.has(0)} changeValue={handleValueChange}>
          {children}
        </ToggleButton>
      )}
    </ButtonGroup>
  )
}
