import { format, parseISO } from 'date-fns'

export const formatDate = ({ dateString, convert = false }) => {
  let dateToFormat = dateString
  if (convert) {
    const [month, day, year] = dateString.split('/')
    dateToFormat = `${year}-${month}-${day}`
  }
  const date = parseISO(dateToFormat)
  return format(date, 'EEE, MMM d')
}
export const getWeekDayFromDate = ({ dateString, convert = false }) => {
  let dateToFormat = dateString
  if (convert) {
    const [month, day, year] = dateString.split('/')
    dateToFormat = `${year}-${month}-${day}`
  }
  const date = parseISO(dateToFormat)
  return format(date, 'EEE')
}
export const getDateAndMonthFromDate = ({ dateString, convert = false }) => {
  let dateToFormat = dateString
  if (convert) {
    const [month, day, year] = dateString.split('/')
    dateToFormat = `${year}-${month}-${day}`
  }
  const date = parseISO(dateToFormat)
  return format(date, 'MMM d')
}

function extractGoogleAnalyticsUserIdFromCookie(gaCookie) {
  if (gaCookie) {
    const userIdParts = gaCookie.split('.').slice(-2)
    if (userIdParts.length === 2) {
      return userIdParts.join('_')
    }
  }
  return undefined
}

function getBrowserCookie(cookieName: string) {
  if (typeof document !== 'object' || typeof document.cookie !== 'string') {
    return undefined
  }
  const name = cookieName + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return undefined
}

export function getGoogleAnalyticsUserIdFromBrowserCookie(cookieName: string) {
  const browserCookie = getBrowserCookie(cookieName)

  if (!browserCookie) {
    return undefined
  }

  return extractGoogleAnalyticsUserIdFromCookie(browserCookie)
}

export const filterChildProductsByAvailability = (childProducts: any) => {
  return childProducts?.filter((childProduct: any) => {
    const isLive = new Date(childProduct?.attributes?.targetGoLiveDateAsDateType) <= new Date()

    const childProductHasInventory = childProduct?.variants?.some((variant: any) => {
      return variant?.availabilityId && variant?.availability?.availableQuantity > 0
    })

    const childProductHasBackorder = childProduct?.variants?.some((variant: any) => {
      return variant?.availabilityId && variant?.availability?.backorderlevel > 0
    })

    return isLive && (childProductHasInventory || childProductHasBackorder)
  })
}

export const getPrice = ({ cartPromotion, promotionVariant, currentVariant, priceByEdsCodes }: any) => {
  if (cartPromotion) {
    return (
      priceByEdsCodes?.custom?.fields?.basePrice?.centAmount ??
      priceByEdsCodes?.value?.centAmount ??
      currentVariant?.price?.centAmount
    )
  } else if (promotionVariant) {
    return promotionVariant?.price?.centAmount
  } else {
    return currentVariant?.price?.centAmount
  }
}
